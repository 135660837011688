<template>
    <div class="container">
        <h2>子弹工作服务协议</h2>
        <p class="p1">更新日期：【2023】年【10】月【11】日</p>
        <p class="p1">生效日期：【2023】年【10】月【11】日</p>
        <p class="p1">子弹工作是由苏州蓝豚互动信息科技有限公司及期关联方(以下简称“蓝豚互动” 或“我们”)提供的互联网软件服务(以下简称“子弹工作”)。本服务协议(以下简称“本协议”)是您(以下简称“您”或“用户”
            )与蓝豚互动之间关于您访问和使用子弹工作以及蓝豚互动提供的各类(包含不限于公司网站、APP、官方客服电话、微信平台、后台管理程序及系统、数据库等)服务(以下简称“本服务”)的协议。</p>
        <p class="p1">
            在本服务创建账户并使用之前，您必须首先同意本协议。<strong>在接受本协议之前</strong>，请您仔细阅读全部内容，如您无法准确理解或不同意本协议的任一内容，请不要访问、使用子弹工作或蓝豚互动提供的各类（任一）服务。<strong>您通过子弹工作小程序点击确认或使用本服务等操作均表示您已阅读并充分理解本协议之内容，具有达成本协议所需的民事权利能力和民事行为能力，与蓝豚互动就使用或订购相应产品和/或服务已达成合意，并同意接受本协议的全部约定内容，受其约束。</strong>
        </p>
        <p class="p1">
            <strong>特别提示您关注</strong>本协议关于<strong>限制、免除蓝豚互动责任的协议、对用户的权利限制协议、违规/违约行为的认定处理、以及管辖法院的选择</strong>等协议内容，前述协议可能以加粗及/或加下划线形式提示您注意。
        </p>
        <p class="p1">
            您同意蓝豚互动有权不时地对本协议进行修订，且毋须另行通知您。修订后的协议将以平台、网页上公布的方式向您告知，公布即生效，并代替原来的服务协议。
        </p>
        <p class="p1">
            <strong>您继续访问和使用子弹工作各类服务即视为您接受修订后的服务协议。</strong>如您不同意修订后的全部或者部分服务协议内容，您有权通过停止访问子弹工作或者拒绝使用相应服务、删除您在子弹工作上的信息和账户等方式以终止服务协议或者使修订后的内容不对您生效。
        </p>
        <p class="p1">
            <strong>如果您代表某个机构而非您个人注册、登录和使用子弹工作，则您将被认为得该机构的充分授权，有权代表该机构同意本服务协议以及服务协议不时的修改和补充。</strong>
        </p>
        <p class="p1"><strong>1.服务内容</strong></p>
        <p class="p2">
            1.1 本协议中的“本服务”指：蓝豚互动向您在子弹工作上展示并提供发薪，打卡，领取工资等各种功能服务，包括但不限于通过移动端小程序方式提供的全部服务，具体服务容以您下载使用的版本为准。
        </p>
        <p class="p2">
            1.2
            若您未从蓝豚互动官方途径合法授权的第三方获取子弹工作服务的，我们无法保证该服务能够正常使用，我们对此种获取方式和使用行为造成的损失不承担任何责任，请您务必作好有效识别，自第三方获取前，认真确认有效地资质、授权证明等资料 。
        </p>
        <p class="p2">
            1.3 根据实际需要和蓝豚互动不时提供的各类服务内容，蓝豚互动可能与您另行签订其他协议(“其他协议”)。同时，即使未另行签订其他协议，您使用子弹工作的具体服务也将被视为您同意蓝豚互动关于该等具体服务的任何要求。
        </p>
        <p class="p1"><strong>如果其他协议和本协议之间存在冲突，应以其他协议为准，但以该冲突和与该协议特定事宜相关为限。 </strong></p>
        <p class="p2">
            1.4
            蓝豚互动保留随时变更、中止或终止部分服务的权利，并保留根据实际情况随时调整子弹工作提供的服务种类、形式的权利。<strong>蓝豚互动不承担因子弹工作提供的任何服务的调整给您造成的损失。</strong>蓝豚互动有权在未来恰当的时机对任何服务内容收取相应的服务费用。
        </p>
        <p class="p2">
            蓝豚互动做出上述调整前会以平台通知、短信或网站公告等方式通知您，并给予您合理期限以完成工资的提现。
        </p>
        <p class="p2">
            1.5 您同意蓝豚互动为满足用户需求、提升产品使用体验等需要调整、优化、升级子弹工作提供的各类服务（包含不限于服务种类、形式等）。如因不可抗力、突发、无法预见等情况，蓝豚互动需要终止向您提供免费服务的，蓝豚互动将会以平台通知、短信或网站公告等方式予以通知。
        </p>
        <p class="p2">
            <strong>您同意若蓝豚互动终止提供任一服务，蓝豚互动的义务仅限于向您返还您尚未提现的金额。您同意蓝豚互动无需因免费服务的调整、优化、升级或终止向您承担责任。</strong>但无论如何，蓝豚互动将尽合理的努力给您预留合理的时间以便您为该等服务变更、中止或终止作出应对。
        </p>
        <p class="p2">
            1.6
            您在使用子弹工作服务前，应充分阅读并理解子弹工作平台对相应服务的描述，尤其是对各类付费服务在功能、服务标准上的限制。<strong>对由于您未能充分理解上述描述和限制而造成的任何损失，蓝豚互动不承担任何法律责任。</strong>
        </p>
        <p class="p2">
            子弹工作免费服务具体内容以蓝豚互动根据实际情况提供，您可根据自己的需求享受对应服务；具体规则以子弹工作平台或公众号公示的内容为准。
        </p>
        <p class="p1">
            <strong>2. 注册及账号管理</strong>
        </p>
        <p class="p1">
            <strong>注册账号：</strong>
        </p>
        <p class="p2">
            如果您是企业账号，在您与本平台确认开通账号后，您可以通过手机号码和验证码进行注册，并获得注册账号。企业用户可以通过手机号码和验证码进行登录账号。
        </p>
        <p class="p2">
            如果您是个人用户，请您理解，本平台为领取薪资平台，为保证领取薪资发正确性和准确性，您需要通过姓名和身份证号码进行注册，并获得注册账号。个人用户通过姓名和身份证号进行登录账号。
        </p>
        <p class="p2">
            您在注册子弹工作平台时应具备中华人民共和国法律法规规定的与您的行为相适应的民事行为能力，能够依据法律规定和本协议约定独立承担相应的法律责任。如您未满18周岁，请您在监护人陪同下仔细阅读并充分理解本协议，在征得监护人的同意后使用我们的服务。您应该使用真实有效的信息注册子弹工作平台。
        </p>
        <p class="p2">
            请您务必妥善保管您的终端及账户、验证码和/或身份要素信息，并对利用该账户和验证码/身份信息所进行的一切活动负全部责任。您若发现任何非法使用用户帐号或安全漏洞的情况，应立即通告子弹工作平台。除非有法律规定或司法裁定，否则，您的账户不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
        </p>
        <p class="p1">
            <strong>账户注销</strong>
        </p>
        <p class="p2">
            （1）个人用户或企业用户可以通过联系本平台申请注销账户。
        </p>
        <p class="p2">
            （2）用户违反法律法规、本协议约定或者子弹工作平台在线的管理规定;依照相关法律法规或应行政司法执法机关的要求，子弹工作平台将注销您的账户。
        </p>
        <p class="p2">
            注销账户后，您将无法使用已注销的账户，无法查找账户中的任何记录和消息，账户中的收益也将作清零处理，账户涉及您的个人信息将被删除或匿名化处理。
        </p>
        <p class="p1">
            <strong>3. 使用规范</strong>
        </p>
        <p class="p2">
            3.1 您充分了解并同意，本平台仅为用户提供服务平台，您应自行对利用服务从事的所有行为及结果承担责任。您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等
        </p>
        <p class="p2">
            3.2 您不得使用子弹工作上传、储存、共享、发送或传播任何敏感信息和违反国家法律法规的信息，包括但不限于下列信息：
        </p>
        <p class="p3">
            - 反对宪法所确定的基本原则的；
        </p>
        <p class="p3">
            - 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </p>
        <p class="p3">
            - 损害国家荣誉和利益的；
        </p>
        <p class="p3">
            - 煽动民族仇恨、民族歧视，破坏民族团结的；
        </p>
        <p class="p3">
            - 破坏国家宗教政策，宣扬邪教和封建迷信的；
        </p>
        <p class="p3">
            - 散布谣言，扰乱社会秩序，破坏社会稳定的；
        </p>
        <p class="p3">
            - 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </p>
        <p class="p3">
            - 侮辱或者诽谤他人，侵害他人合法权益的；
        </p>
        <p class="p3">
            - 含有法律、行政法规禁止的其他内容的。
        </p>
        <p class="p2">
            您承诺发表言论要：爱国、守法、自律、真实、文明。不传播任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、危害国家安全的、泄露国家机密的、破坏国家宗教政策和民族团结的以及其它违反法律法规及政策的内容。若您的行为不符合以上提到的服务协议，子弹工作将作出独立判断立即暂停或终止您的服务账号。您需自行对其全部行为承担法律责任，子弹工作不承担任何法律责任。
        </p>
        <p class="p2">
            3.4 您不得发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容。
        </p>
        <p class="p2">
            3.5 您不得制作、复制、发布、传播涉及他人隐私、个人信息或资料的信息。
        </p>
        <p class="p2">
            3.6 您不得发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的内容。
        </p>
        <p class="p2">
            3.7
            <strong>您应当对您使用子弹工作的一切行为及所有在您账户下的活动（不论是您还是您授权他人的活动）负责。</strong>您不得利用子弹工作从事违反法律法规、违反本用户协议约定、危害网络安全、危害计算机系统安全、破坏子弹工作产品完整性或其他侵害子弹工作及任何第三人利益的活动。<strong>若您从事了上述行为，蓝豚互动有权暂停或终止您的账户，并拒绝您于现在和未来使用子弹工作之全部或部分功能，由此对蓝豚互动或第三人造成的损失或由此产生的一切法律责任均由您全部承担。</strong>
        </p>
        <p class="p1">
            <strong>4. 服务变更、中断、终止</strong>
        </p>
        <p class="p2">
            鉴于网络服务的特殊性，您同意子弹工作平台有权合理变更、中断或终止部分或全部的服务（包括收费服务）。子弹工作平台变更、中断或终止的服务，子弹工作平台应当在变更、中断或终止之前通知您。
        </p>
        <p class="p2">
            如发生下列任何一种情形，子弹工作平台有权变更、中断或终止向您提供的免费服务或收费服务，而无需对您或任何第三方承担任何责任：
        </p>
        <p class="p3">
            （1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
        </p>
        <p class="p3">
            （2）您违反相关法律法规或本协议的约定；
        </p>
        <p class="p3">
            （3）按照法律规定或有权机关的要求；
        </p>
        <p class="p3">
            （4）安全的原因或其他必要的情形。
        </p>
        <p class="p1">
            <strong>5. 内容和知识产权</strong>
        </p>
        <p class="p2">
            5.1 您在子弹工作提交的内容之所有权归您所有。您明白蓝豚互动对您提供的任何
            内容不在任何方面负责，蓝豚互动没有义务预先审查此类内容。但是，子弹工作有权随时决定您提供的任何内容是否符合相关法律规定或本协议的相关约定，并可随时预先审查、转移、拒绝、修改和/或删除您上传的内容，而不需事先通知您。若您使用本服务的行为存在或涉嫌任何违反国家法律法规或侵犯任何第三方合法权益的情形，蓝豚互动有权直接删除该等违反规定的信息，并可以暂停或终止向您服务。
        </p>
        <p class="p2">
            5.2
            子弹工作的所有权及所有与子弹工作服务相关的知识产权归蓝豚互动所有。您认同蓝豚互动拥有的所有权及知识产权，并承诺尊重及不以任何方式侵害蓝豚互动对子弹工作享有的所有权及知识产权。未经蓝豚互动书面许可，您不能复制、拷⻉、转让、许可或提供他人使用上述知识成果，或者私自使用任何部分代码和外观设计，否则您应承担相应的法律责任，<strong>且蓝豚互动有权终止服务且不退还任何费用。</strong>
        </p>
        <p class="p2">
            5.3 对于企业用户，我们可能在我们的网站、我们举办的活动或其他宣传推广中使用企业用户的名称、字号、商标等信息或标识以对本服务进行宣传。<strong>当您完成注册或下单的，即视为您了解并同意本条约定行为。</strong>
        </p>
        <p class="p1">
            <strong>6. 隐私政策 </strong>
        </p>
        <p class="p2">
            6.1
            您在使用子弹工作前，通过阅读和点击同意《子弹工作隐私政策》并实际使本服务，即表示您授权蓝豚互动合法收集和使用有关您及您所使用本服务的技术性或诊断性信息。基于提供服务所必需，手机端用户仅在用于不限于邀请人员、在线报案、反馈建议和问题时用录音、拍照、读取相册等权限。收集到的这些信息将用于改进子弹工作服务的内容和技术，提升我们的服务品质。蓝豚互动不会将您的信息和内容分享或出售给第三方。但以下情况除外：
        </p>
        <p class="p3">
            - 基于特定的服务目的且事先获得您的明确授权；
        </p>
        <p class="p3">
            - 遵守适用的法律法规；
        </p>
        <p class="p3">
            - 遵守法院命令或其他法律程序的规定；
        </p>
        <p class="p3">
            - 遵守相关政府主管部⻔的要求；
        </p>
        <p class="p3">
            - 与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的情形下；
        </p>
        <p class="p3">
            - 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        </p>
        <p class="p3">
            - 蓝豚互动认为为遵守适用的法律法规、维护社会公共利益、或保护蓝豚互动的客户、其他用户或雇员的人身和财产安全或合法权益所合理必需的情形下；
        </p>
        <p class="p3">
            - 您违反了本服务协议，且蓝豚互动出于维权需要向第三方提供的情形下；
        </p>
        <p class="p3">
            - 法律法规规定的其他情形。
        </p>
        <p class="p2">
            6.2
            保护用户个人信息是蓝豚互动的一项基本原则，<strong>蓝豚互动将按照《子弹工作隐私政策》的约定收集、使用、储存和分享您的个人信息，以及您如何管理您的个人信息、我们如何采取必要措施保障您的个人信息安全、我们响应您的权利请求等。您声明并保证，您已仔细阅读、完全理解并同意上述《子弹工作隐私政策》的全部内容。若您不同意本协议及《子弹工作隐私政策》，请您立即停止使用我们提供的任何服务或产品；若您继续或持续使用服务或产品的，则视为您已充分理解并同意本条款及《子弹工作隐私政策》。</strong>
        </p>
        <p class="p1">
            <strong>7. 数据和信息安全 </strong>
        </p>
        <p class="p2">
            <strong>
                7.1
                我们与您共同负有维护账号安全的责任。我们在目前的技术水平下努力保护您的账号在服务器端的安全，并不断更新技术措施。但互联网并非绝对安全的环境，我们特别提醒您应该妥善保管您的账号和密码，杜绝私自转借乃至出售账号等有害于账号安全的行为。此外，蓝豚互动建议您采用复杂字符组合设定您的账号密码。请您理解，蓝豚互动不对因您自身有违相关法律法规规定和本协议约定使用账号的行为及其所导致的数据未经授权访问、泄露、破坏乃至销毁等造成的损失承担责任。
            </strong>
        </p>
        <p class="p2">
            <strong>
                7.2
                蓝豚互动将依照法律、行政法规的规定和国家标准的强制性要求，采取合理的技术措施和其他必要措施，努力保护您的数据和信息安全。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。
            </strong>
        </p>
        <p class="p2">
            <strong>
                7.3
                您知悉并认可，子弹工作服务是基于第三方云服务所提供的SAAS服务，鉴于网络服务的特殊性（包括但不限于云服务器的稳定性问题、通讯基础设施故障、网络黑客攻击、政府管制等行为的存在及其他我们无法控制的情形），我们提供的服务可能会出现中断、不能及时传送信息或其他不能满足您的要求的情况，请您知悉并理解，该情况将不视为我们违反本协议约定，但我们将尽可能及时通过网站公告、系统通知、短信或者其他合理的方式通知您。
            </strong>
        </p>
        <p class="p1">
            <strong>8. 免责声明 </strong>
        </p>
        <p class="p1">
            <strong>您知悉并同意，下列情况下，蓝豚互动无需向您承担任何责任：</strong>
        </p>
        <p class="p2">
            <strong>
                8.1 由于您维护不当或保密不当，导致数据、资料、口令、密码等丢失或泄漏，或其他非因蓝豚互动原因导致的个人信息的泄漏，蓝豚互动不承担任何法律责任；
            </strong>
        </p>
        <p class="p2">
            <strong>
                8.2 任何由于不可抗力、黑客攻击、电脑病毒侵入、网络运营商原因或政府管制等蓝豚互动无法合理控制的原因而造成子弹工作使用中止、中断、延误、使用限制、使用异常、使用不能、暂时性网站关闭等，蓝豚互动不承担任何法律责任；
            </strong>
        </p>
        <p class="p2">
            <strong>
                8.3 您应对通过子弹工作上传或储存文件、输入数据的合法性以及获得文件、数据、内容的方式的合法性负责，因上述文件、数据、内容引发的一切纠纷，由您承担全部法律责任，蓝豚互动不承担任何法律责任；
            </strong>
        </p>
        <p class="p2">
            <strong>
                8.4
                互联网是一个开放平台，如果您将图片、文字等内容上传并传播，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，您应充分意识到此类风险的存在。您同意使用蓝豚互动服务所存在的此类风险及后果将完全由您自己承担，蓝豚互动不承担任何责任。
            </strong>
        </p>
        <p class="p2">
            <strong>
                8.5
                您理解并确认，受现有技术所限，蓝豚互动无法保证其所提供的服务毫无瑕疵，但我们承诺将不断提升服务质量及服务水平，为您提供更加优质的服务。据此，您同意：如果蓝豚互动所提供的服务虽然存在瑕疵，但该等瑕疵是当时行业技术水平所无法避免的，其将不被视为违约，蓝豚互动无须因此向您承担任何责任。
            </strong>
        </p>
        <p class="p2">
            <strong>
                8.6
                我们通过中华人民共和国境内的设施提供服务，我们无法确保您的使用行为、发布的信息及内容在其他国家或地区是适当的、可行的，当您在其他司法辖区使用本软件及相关服务时，您应自行确保其遵守当地的法律法规，我们对此不承担任何责任。
            </strong>
        </p>
        <p class="p2">
            <strong>
                8.7
                如我们发现、或通过第三方举报或投诉获知，您发布的内容存在或涉嫌违反国家法律法规或本软件规则的，我们有权依据合理判断不经通知立即采取一切必要措施（包括但不限于立刻删除您所发布的相关内容、冻结账户封号、向公安机关报案等）或配合国家网信部门、公检法等国家机关的执法要求对帐户做相应处理以减轻或消除您行为所造成的影响。
            </strong>
        </p>
        <p class="p1">
            <strong>9. 服务变更/中断/终止 </strong>
        </p>
        <p class="p2">
            9.1
            您可随时注销您的账户并停止使用子弹工作服务。注销账户的方式可遵照子弹工作平台的指引。您声明并保证，在您自主注销账户前已经将您上传、储存在子弹工作中的数据下载、备份完毕。<strong>您自主注销账户并经过缓冲期后，子弹工作将不再负有保留您数据的任何法定或者约定义务。</strong>
        </p>
        <p class="p2">
            9.2
            您理解并同意，如因系统维护或升级的需要而暂停子弹工作服务、调整服务功能的，蓝豚互动将尽可能事先在平台上进行通告，以便于您提前做好商业安排，努力将对您带来的实际影响降到最低，<strong>但不因此承担任何法律责任。</strong>
        </p>
        <p class="p2">
            9.3 当出现如下任一情形时，蓝豚互动可径行终止或暂停您使用所有或部分账户和/或本服务；<strong>若您存在主观故意或重大过失情形的，蓝豚互动无须向您退还任何订阅费用：</strong>
        </p>
        <p class="p3">
            - 您提供的资料不真实，或违反其他用户个人资料提供规则；
        </p>
        <p class="p3">
            - 您违反法律法规、本协议或您与蓝豚互动的其他约定；
        </p>
        <p class="p3">
            - 您要求取消或终止您的账户；
        </p>
        <p class="p3">
            - 未经蓝豚互动同意，将子弹工作产品用于商业目的或者其他任何营利性用途；
        </p>
        <p class="p3">
            - 您对子弹工作的访问或使用可能会对子弹工作服务、蓝豚互动或第三方造成安全风险；
        </p>
        <p class="p3">
            - 需要听从法庭传票、法律命令或遵循法律程序；
        </p>
        <p class="p3">
            - 根据相关法律法规或政府机构的要求；
        </p>
        <p class="p3">
            - 自然灾害、战争等不可抗力；
        </p>
        <p class="p3">
            - 本协议发生变更或修改，您不同意变更或修改后的用户协议。
        </p>
        <p class="p2">
            9.4
            您理解并认可，为技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化，<strong>蓝豚互动不保证永久地提供某种服务，或可能变更所提供服务的形式、规格或其他</strong>方面，在终止该种服务或进行此种变更前，蓝豚互动将尽最大努力且提前以平台通知、短信或网站公告等一种或多种方式进行事先通知；如终止提供该种服务的，蓝豚互动将尽合理的努力给您预留合理的时间，妥善处理后续事宜。
        </p>
        <p class="p2">
            9.5 一旦您的账户被终止，您将无法正常使用本服务。此外，您可以选择在账户终止前删除您的账户下的所有信息和内容。 
        </p>
        <p class="p1">
            <strong>10. 本服务协议的完善和修改 </strong>
        </p>
        <p class="p2">
            10.1 蓝豚互动有权根据互联网的发展和中华人民共和国有关法律、法规的变化，不时地完善和修改本协议。蓝豚互动保留随时修改本协议的权利，并会将修改后的服务协议在子弹工作官网上公布，您有义务及时查看官网上的消息更新，该等公布行为即可被合理地视为对您进行了有效通知送达。<strong>您在使用蓝豚互动的服务时，有必要对最新的《子弹工作服务协议》进行仔细阅读和重新确认，经您确认和同意后，当发生相关争议时，以最新的服务协议为准。</strong> 
        </p>
        <p class="p2">
            10.2 如果您不同意蓝豚互动对本协议所做的修改，您应立即停止使用子弹工作服务。如果用户继续使用子弹工作服务，则视为用户接受我方对本协议所做的修改。 
        </p>
        <p class="p1">
            <strong>11. 青少年特别协议 </strong>
        </p>
        <p class="p2">
            11.1 子弹工作服务主要面向成年人。若您是未成年人，<strong>请您确保您的监护人已仔细阅读、理解并接受本协议。并在征得您的监护人同意的前提下使用蓝湖服务或向蓝豚互动提供信息。</strong>
        </p>
        <p class="p2">
            11.2 如您的监护人不同意您按照本协议使用子弹工作服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。 
        </p>
        <p class="p2">
            11.3 您声明并保证，您是具有完全民事行为能力的自然人。若您违背上述保证购买、使用子弹工作付费服务的，蓝豚互动不因此承担任何包括但不限于返还价款在内的任何法律责任，由此造成的损失应由您或您的监护人自行承担。
        </p>
        <p class="p1">
            <strong>12. 法律适用及争议解决</strong>
        </p>
        <p class="p2">
            12.1 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国⼤陆法律；如法律无相关规定的，参照商业惯例或者行业惯例。
        </p>
        <p class="p2">
            12.2 您因使用子弹工作服务所产生及与本协议有关的⼀切争议，由蓝豚互动与您协商解决。协商不成时，任何⼀方均可向蓝豚互动住所地人民法院提起诉讼。 
        </p>
        <p class="p2">
            12.3 若用户利用本服务从事任何违法或侵权行为，由用户自行承担全部责任，蓝豚互动不承担任何法律责任。因此给蓝豚互动或任何第三方造成任何损失的，用户应负责全额赔偿。
        </p>
        <p class="p1">
            <strong>13. 与我们联系</strong>
        </p>
        <p class="p2">
            当您有其他的投诉、建议或反馈时，请与我们联系。您可以将您的问题发送⾄lantunhudong@163.com，或邮寄到如下地址：江苏省苏州市昆山市花桥镇商祥路36领峰国际1号楼601（收），邮编：215300
        </p>
        <p class="p1">
            <strong>14. 其他协议</strong>
        </p>
        <p class="p2">
            14.1 <strong>蓝豚互动有权不经您的事先单独同意，将本条款的全部或者部分权利义务转移给蓝豚互动的关联公司时，但我们也会尽力（包括但不限于电子邮件、短信或者官网公告）与您取得联系并就相关事宜进行协商，与此同时，蓝豚互动保证不会实质性降低您可获得服务的质量。</strong> 
        </p>
        <p class="p2">
            14.2 未经蓝豚互动事先书面同意，您不得将本协议项下的任何权利义务转让给第三人。
        </p>
        <p class="p2">
            14.3 本协议部分协议因任何原因被宣告无效或不可履行的，不影响其他协议的效力，其他协议仍应继续适用。任何无效或不可履行的协议将被替换为与本协议⽬的和意图⼀致的另⼀协议。 
        </p>
        <p class="p2">
            14.4 因超出蓝豚互动合理控制的情况致使蓝豚互动未能遵守所有协议和条件，则蓝豚互动对这类不履约概不承担任何责任。 
        </p>
        <p class="p2">
            14.5 蓝豚互动可通过站内消息或您在注册账户、使用子弹工作服务过程中提供电话、电⼦邮件或其他联系方式向您发出通知。上述通知⼀经发出即视为对您产生效力，<strong>您有义务保证您的联系方式的有效性。</strong> 
        </p>
        <p class="p2">
            14.6 除非蓝豚互动以书面方式确认并同意，蓝豚互动未履行本协议中任何权利或协议的行为不构成蓝豚互动对相关权利或协议的放弃。 
        </p>
        <p class="p2">
            14.7 最近修订于【2023】年【10】月【11】日。蓝豚互动保留一切权利。 
        </p>
    </div>
</template>
<style scoped>
h2 {
    text-align: center;
}

h4 {
    text-align: right;
}

.container {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 16px;
    user-select: none;
}

p {
    line-height: 26px;
}</style>