<template>
  <div class="container">
    <h2>子弹工作隐私政策</h2>
    <p class="p1">
      苏州蓝豚互动信息技术有限公司及/或其关联方（以下简称“蓝豚互动”、“我们”）尊重并保护个人信息，并且将以高度勤勉和审慎的义务对待这些信息。在您访问和使用子弹工作以及蓝豚互动提供的其他服务时，我们将按照本隐私协议（以下简称“本协议”）收集、处理及披露您的信息。我们希望通过本协议向您清晰地介绍我们对您信息的处理方式，以帮助您了解维护自己个人信息的方式。在接受本协议之前，请您仔细阅读全部内容，<strong>特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后开始使用。如果您或者您的监护人不同意本隐私政策的任何内容，您应该立即停止使用。</strong>如您无法准确理解或不同意本协议的任一内容，请不要访问、使用子弹工作或蓝豚互动提供的其他任何服务。您通过网络页面点击确认、实际使用子弹工作服务或蓝豚互动所提供的其他服务等操作均表示您已阅读并充分理解本协议之内容，受其约束。本协议属于《子弹工作服务协议》不可分割的一部分。如对本政策内容有任何疑问、意见或建议，您可通过本协议中提供的各种联系方式与我们联系。
    </p>
    <p class="p1">本协议将帮助您了解以下内容：</p>
    <p class="p1 p2">1.本协议的适用范围</p>
    <p class="p1 p2">2.我们如何收集和使用您的个人信息</p>
    <p class="p1 p2">3.我们如何使用cookie和同类技术</p>
    <p class="p1 p2">4.我们如何共享、转让、公开披露您的个人信息</p>
    <p class="p1 p2">5.我们如何保护和保存您的个人信息</p>
    <p class="p1 p2">6.您的权利</p>
    <p class="p1 p2">7.我们如何处理未成年人的个人信息</p>
    <p class="p1 p2">8.您的个人信息如何在全球范围转移</p>
    <p class="p1 p2">9.本协议如何更新</p>
    <p class="p1 p2">10.如何联系我们</p>
    <p class="p11"><strong>1.本协议的适用范围</strong></p>
    <p class="p1">当您同意本协议并开始使用我们的任何产品和服务时，本协议即适用，无论该产品和服务是否单独设置了隐私协议，也无论您是浏览用户（访客）还是注册登录用户。请您注意，本协议不适用于以下情况：</p>
    <p class="p1">• 通过我们的产品和/或服务而接入的第三方服务（包括任何第三方网站）收集的信息；</p>
    <p class="p1">• 通过在我们产品和/或服务中进行广告服务的其他公司或机构所收集的信息。请您知悉：如果您浏览第三方网站或使用第三方的产品和/或服务时向该第三方提供您的个人信息，您的信息应当适用该第三方的隐私声明或类似协议，我们对任何第三方不当使用或披露由您提供的信息不承担任何法律责任，无论您登录或浏览上述网站、软件，或使用其产品和/或服务是否基于蓝豚互动的链接或引导。<strong>强烈建议您在使用上述第三方服务前先行了解、确认服务提供方的隐私保护情况。 </strong></p>
    <p class="p11"><strong>2.我们如何收集和使用您的个人信息</strong></p>
    <p class="p1 p2">（一）我们收集您的个人信息的情形</p>
    <p class="p1">
      “个人信息”指个人信息是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人的各种信息，包括但不限于个人身份信息（如姓名）、网络身份识别信息（如账号名、邮箱地址、网络IP）、设备信息（Mac地址）。“非个人身份信息”指任何与您有关但实际上不可直接或间接辨认您身份的信息，包括以汇集、匿名或化名方式提供的个人信息。<strong>您知悉并同意，无法识别出您个人的信息不属于个人信息。</strong>
    </p>
    <p class="p1">
      您所属组织提供的与您相关的个人信息。您所属组织在为了让您使用产品和/或服务而开设您的账户时，将会提供与您相关的若干个人信息，包括您的账户名、企业邮箱、部门组织架构、职位。<strong>您知悉并确认您所属组织向我们提交的上述信息已经基于您的明确授权。</strong>
    </p>
    <p class="p1">我们提供产品和/或服务时，可能会收集、储存下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法使用和享受我们提供的某些产品和服务，或者无法达到相关产品和/或服务拟达到的效果。</p>
    <p class="p1 p2">2.1.帮助您成为我们的付费用户</p>
    <p class="p1 p3">
      当您在子弹工作平台上创建账户时，您须向我们提供名称，设置、确认您的<strong>登录密码</strong>，提供您在中华人民共和国境内手机号码或电子邮箱账户。您提交的手机号码或邮箱账户用于您注册、登录、绑定账户、密码找回时接受验证码，并且作为您与蓝豚互动指定的联系方式之一，用来接受相关业务通知（如新品上线、服务变更等）。</p>
    <p class="p1">您知悉并同意，为帮助您更便捷地体验并使用蓝豚互动提供的其他产品或服务，我们将在获得您授权的情况下将您所创建的账号及相关信息用于帮助您生成对应应用的账号。</p>
    <p class="p1">
      在您注册完成子弹工作账户后，您可以自主选择在账户中继续填写您的信息，包括（1）您的其他个人信息，如性别、工作单位;（2）联系信息，如地址;（3）绑定您的微信。您提供的本条第（1）类信息，会帮助我们更了解您对于蓝豚互动服务需求，以便蓝豚互动有针对性地向您推广、介绍产品；您提供的本条第（2） 类信息，会帮助我们通过如上方式向您推广、宣传产品、邮寄业务通知（含账单）或与您进行业务沟通；您提供的本条第（3）类信息，会帮助我们为您实现微信消息的提醒功能。
    </p>
    <p class="p1 p3">2.1.3.如果您仅需使用子弹工作平台的浏览基本服务，您不需要注册成为我们的付费用户及提供上述信息。</p>
    <p class="p1 p2">2.2.向您提供服务</p>
    <p class="p1 p3">2.2.1. 蓝豚互动可能会根据相关法律规定要求您提供真实身份信息以进行实名认证，您应通过您的账号提供您的真实身份信息。为方便您提供此等信息，蓝豚互动为您提供了基于手机号码的实名认证，将收集您的手机号码，您可以根据您的身份属性选择适合的方式。您理解并同意蓝豚互动有权自行或委托第三方，审查您在实名认证时提供的信息是否真实、准确及有效。</p>
    <p class="p1 p3">2.2.2. 在您使用服务过程中，我们会收集以下的信息：</p>
    <p class="p1 p4">
      2.2.2.1. 设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯⼀设备标识符等软硬件特征信息）、设备所在位置相关信息（包括IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝⽛和基站等传感器信息）。我们可能会将前面两类信息进行关联，以便我们能在不同设备上为您提供⼀致的服务。
    </p>
    <p class="p1 p4">
      2.2.2.2. 日志信息：当您使用我们的网站或客户端提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营 商、使用的语⾔、访问日期和时间及您访问的网⻚记录等。
    </p>
    <p class="p1 p4">
      2.2.2.3. 用户账户的支持信息：基于您使用子弹工作服务而产生的用户的咨询记录、报障记录和针对用户故障的排障过程（包括通信或通话记录），蓝豚互动将通过记录、分析这些信息以便更及时响应您的帮助请求，以及用于改进服务。
    </p>
    <p class="p1 p4">
      2.2.2.4. 录⾳、录像、照⽚信息：指您使用我们的特定功能（包括语⾳搜索、扫码、拍照）时，在获得您针对调取特定权限的授权后，我们将收集上述信息。拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用我们的产品和/或服务的其他功能。
    </p>
    <p class="p1 p4">
      2.2.2.5. 位置信息：指您开启设备定位功能并使用我们基于位置提供的相关产品和/或服务时，我们收集的有关您位置的信息，包括：您通过具有定位功能的移动设备使用我们的产品和/或服务时，我们通过GPS或WiFi等方式收集的您的地理位置信息。
    </p>
    <p class="p1 p4">
      2.2.2.6. 付费相关信息：为帮助您顺利完成子弹工作付费服务的订阅、退订及支付、退款，并在订阅期间持续为您提供客服、技术支持等服务，蓝豚互动会要求您提供完整的联系方式及支付、退款账户等信息。
    </p>
    <p class="p1 p3">
      2.2.3.我们在向您服务时，我们会按照本协议的约定收集、使用、存储、对外提供及保护您的个人信息；超出本协议约定收集您的个人信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后收集提供相应服务所必要的信息；如您选择不提供前述信息，将会影响到您使用相应服务。
    </p>
    <p class="p1 p2">
      2.3. 其他方分享的您的信息
    </p>
    <p class="p1 p3">
      2.3.1.当您使用我们的关联公司、合作伙伴的服务时，他们在您的授权下可能向我们分享您的账号信息；
    </p>
    <p class="p1 p3">
      2.3.2.若您以其他方式关联登录、使用我们的服务，我们会向第三方请求您的个人信息，对于我们需要但第三方无法提供的个人信息，我们仍会要求您提供。如果您拒绝提供，将可能导致您无法正常使用我们的产品和/或服务的某些功能；如果您同意提供，此时，您将授权我们读取您在该第三方账号注册的相关信息（如昵称、头像）；
    </p>
    <p class="p1 p3">
      2.3.3.我们将会依据相关法律法规要求以及与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律法规规定的前提下，使用从第三方获得的您的个人信息。
    </p>
    <p class="p1 p2">
      2.4. 我们希望提供给您的产品和/或服务是完善的，所以我们会不断改进我们的产品和/或服务，包括技术。这意味着我们的产品和/或服务可能会经常推出新功能，可能需要收集新的信息。如果我们收集的个人信息或使用方式出现变化或调整，我们会通过适当的方式告知您，并且可能以修订本协议的方式再次征询您的授权。
    </p>
    <p class="p1 p2">
      2.5. 我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。如需跨境存储，我们会单独再征得您的授权同意。
    </p>
    <p class="p1 p2">
      2.6. 您授权我们在您使用我们的产品及服务的期间使用您的个人信息，若您注销账号，我们将停止使用并在合理期限内删除或者匿名化您的个人信息。但基于相关法律法规要求（如《电子商务法》要求留存交易订单信息至少三年）或者解决投诉或者争议的客观需要，部分信息将继续保留特定时间，直至法律规定期限届满或者信息保留的目的不再必要。此外请您注意，由于匿名化后的信息无法识别或者关联您，因此不属于个人信息，对于已匿名化的信息的存储期限和处理，我们无需取得您的授权同意，也无需作出通知。
    </p>
    <p class="p1">
      （⼆）我们使用您的个人信息的情形
    </p>
    <p class="p1 p2">
      2.7. 我们出于如下目的，使用您提交或者我们主动收集的个人信息：
    </p>
    <p class="p1 p3">
      2.7.1.为了向您提供服务，我们会向您发送必要的信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知；
    </p>
    <p class="p1 p3">
      2.7.2.用于数据分析，以便向您提供更加优质的产品和/或服务进⼀步了解您如何接入和使用我们的产品和/或服务，从而针对性地回应您的个性化需求，例如语⾔设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
    </p>
    <p class="p1 p3">
      2.7.3.为了维护、改进服务，向您提供更符合您个性化需求的信息展示，我们利用您的服务使用信息做出特征模型并进行用户画像，向您展示、推送信息和可能的商业广告，包括但不限于关于蓝豚互动产品的新闻以及市场活动及优惠促销信息、蓝豚互动合作第三方的推广信息，或其他您可能感兴趣的内容。以上信息会明确标注“定制”字样，如果您不想接收我们给您发送的商业性电子信息，您可通过短信提示回复退订或根据信息中提供的退订方式撤回同意；
    </p>
    <p class="p1 p3">
      2.7.4.我们可能以个人信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息；
    </p>
    <p class="p1 p3">
      2.7.5.为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓⻥网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或蓝豚互动相关协议、规则的情况，我们可能使用您的会员信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施；
    </p>
    <p class="p1 p3">
      2.7.6. 软件认证或管理软件升级；
    </p>
    <p class="p1 p3">
      2.7.7.让您参与有关我们产品和服务或通过我们的产品和服务发起的调查，是否参与调查将由您全权决定，并且由您自行选择提供哪些信息；
    </p>
    <p class="p1 p3">
      2.7.8.出于安全、合法调查等目的，我们可能使用您的数据做数据汇总、分析、挖掘（包括商业化利用），但这些信息都采用匿名的方式，不能识别您的身份；
    </p>
    <p class="p1 p2">
      2.8. 如超出收集个人信息时所声称的目的，或者在超出具有直接或合理关联的范围使用您的个人信息前，我们会再次向您告知并征得您的明示同意；
    </p>
    <p class="p1 p2">
      2.9. 为了向您提供更好的服务体验、改善我们的产品和/或服务或基于您所同意的其他用途，在符合相关法律法规的前提下，我们可能会在另行征得您同意的前提下将通过我们的某⼀项产品和/或服务所收集的信息，以汇集信息或者个性化的方式，用于您所使用的我们所提供的其他产品和/或服务。例如，在您使用我们的⼀项产品或服务时所收集的信息，可能在您所使用的另⼀产品或服务中用于向您提供特定内容，或向您展示与您相关的、而非普遍推送的信息。
    </p>
    <p class="p1">（三）事先征得授权同意的例外</p>
    <p class="p1 p2">
      2.10.请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意：
    </p>
    <p class="p1 p3">
      2.10.1.与国家安全、国防安全直接相关的；
    </p>
    <p class="p1 p3">
      2.10.2.与公共安全、公共卫生、重大公共利益直接相关的；
    </p>
    <p class="p1 p3">
      2.10.3.与犯罪侦查、起诉、审判和判决执行等直接有关的；
    </p>
    <p class="p1 p3">
      2.10.4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p class="p1 p3">
      2.10.5. 所收集的个人信息是您自行向社会公众公开的；
    </p>
    <p class="p1 p3">
      2.10.6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p class="p1 p3">
      2.10.7.根据您的要求向您提供产品或服务所必需的；
    </p>
    <p class="p1 p3">
      2.10.8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
    </p>
    <p class="p1 p3">
      2.10.9.法律法规规定的其他情形。
    </p>
    <p class="p1">
      3. 我们如何使用Cookie和同类技术
    </p>
    <p class="p1 p2">
      3.1. 为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为Cookie的⼩数据文件。Cookie通常包含标识符、站点名称以及⼀些号码和字符。蓝豚互动只能读取蓝豚互动提供的Cookies。
    </p>
    <p class="p1 p2">
      3.2. 借助于Cookie，能够存储您的偏好或购买清单中的付费服务等数据。当下⼀次您再次访问的时候，我们将显示您需要的信息；或由蓝豚互动通过cookies文件来辨识您的来源网站，以便蓝豚互动能追踪蓝豚互动的广告效果。
    </p>
    <p class="p1 p2">
      3.3. 我们不会将Cookie用于本协议所述目的之外的任何用途。您可根据自己的偏好管理Cookie，您也可以清除计算机上保存的所有Cookie。大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每⼀次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
    </p>
    <p class="p1 p2">
      3.4. 除Cookie外，我们有可能还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是⼀种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
    </p>
    <p class="p1">
      4. 我们如何共享、转让、公开披露您的个人信息
    </p>
    <p class="p1 p2">
      4.1. 共享和披露⼀般情况下，我们不会与其他组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p class="p1 p3">
      4.1.1.在获取明确同意的情况下共享：获得您的明确同意后，我们可能会基于为您提供特定服务需要时与其他方共享您的个人信息；
    </p>
    <p class="p1 p3">
      4.1.2.与关联方及授权合作伙伴共享：我们可能委托受信赖的关联方或合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的关联方及合作伙伴无权将共享的个人信息用于任何其他用途；
    </p>
    <p class="p1 p3">
      4.1.3.在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
    </p>
    <p class="p1 p3">
      4.1.4. 法律法规规定的其他情形。
    </p>
    <p class="p1 p3">
      4.2. 共享您的个人信息，我们将遵循如下原则
    </p>
    <p class="p1 p3">
      4.2.1.授权同意原则：未经您的同意，我们不会共享您的个人信息，除非共享的个人信息是去标识化处理后的信息，且共享的第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意；
    </p>
    <p class="p1 p3">
      4.2.2.合法正当与最⼩必要原则：共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。
    </p>
    <p class="p1 p3">
      4.2.3.安全审慎原则：我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接⼝（API）进行严格的安全监测，以保护数据安全。
    </p>
    <p class="p1 p2">
      4.3. 实现功能或服务的共享信息
    </p>
    <p class="p1 p3">
      4.3.1. 如您是企业/组织用户的最终用户，您所属的企业/组织用户管理员通过本软件选择开通、管理并使用第三方的产品或服务时，我们可能需要向该第三方服务提供商共享您开通、管理并使用第三方产品或服务所必要的信息，否则您将无法使用第三方服务，企业/组织用户管理员开通使用第三方服务时，需阅读并充分了解并遵守第三方服务的产品功能及隐私保护政策。如您作为个人用户通过本软件选择开通、管理并使用第三方的产品或服务时，您同意我们向该第三方服务提供商共享您开通、管理并使用第三方产品或服务所必要的的信息，否则将您无法使用第三方服务，您开通使用第三方服务时，需阅读并充分了解并遵守第三方服务的产品功能及隐私政策；
    </p>
    <p class="p1 p3">
      4.3.2.登录、绑定本软件内产品：经您同意，当您使用子弹工作账号登录第三方的产品或服务时，我们会将您的基础信息（姓名、头像）及其他您授权的信息与前述产品或服务共享。
    </p>
    <p class="p1 p2">
      4.4. 转让我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </p>
    <p class="p1 p3">
      4.4.1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
    </p>
    <p class="p1 p3">
      4.4.2.在蓝豚互动与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
    </p>
    <p class="p1 p2">
      4.5. 公开披露我们仅会在以下情况下，公开披露您的个人信息：
    </p>
    <p class="p1 p3">
      4.5.1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
    </p>
    <p class="p1 p3">
      4.5.2.或为保护子弹工作平台及其关联方用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或蓝豚互动平台相关协议、规则披露关于您的个人信息；
    </p>
    <p class="p1 p3">
      4.5.3.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
    </p>
    <p class="p1 p2">
      4.6. 您主动与他人共享的信息
    </p>
    <p class="p1 p3">
      4.6.1.您可以通过我们的平台进行搜索问题上报、评价等，以帮助更多的用户享受到更精准的信息。
    </p>
    <p class="p1 p3">
      4.6.2.您可以利用我们的分享功能与您的特定或不特定社交好友（如微信、微博）共享您的个人信息。请您在分享前充分考虑信息接收人的信誉情况，并建议您查看您利用的社交网络或第三方服务提供商的隐私声明，以了解他们如何处理您的信息，以便审慎决策。
    </p>
    <p class="p1 p2">
      4.7. 共享、转让、公开披露个人信息时事先征得授权同意的例外以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p class="p1 p3">
      4.7.1. 与国家安全、国防安全有关的；
    </p>
    <p class="p1 p3">
      4.7.2. 与公共安全、公共卫生、重大公共利益有关的；
    </p>
    <p class="p1 p3">
      4.7.3. 与犯罪侦查、起诉、审判和判决执行等有关的；
    </p>
    <p class="p1 p3">
      4.7.4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p class="p1 p3">
      4.7.5. 您自行向社会公众公开的个人信息；
    </p>
    <p class="p1 p3">
      4.7.6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p class="p1">
      5. 我们如何保护和保存您的个人信息
    </p>
    <p class="p1 p2">
      5.1. 蓝豚互动非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息。防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p class="p1 p2">
      5.2. 我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本协议所述目的所需的期限内保留您的个人信息，除非受到法律的允许。超出上述个人信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。
    </p>
    <p class="p1 p2">
      5.3. 请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
    </p>
    <p class="p1 p2">
      5.4. 在不幸发生个人信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐⼀告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p class="p1 p2">
      5.5. 我们提醒您注意，互联网并非绝对安全的环境，当您通过我们的网站中嵌入的第三方社交软件、电子邮件、短信等与其他用户交互您的地理位置或行踪轨迹信息时，不确定第三方软件对信息的传递是否完全加密，注意确保您个人信息的安全。
    </p>
    <p class="p1 p2">
      5.6. 如出现子弹工作产品和服务停止运营的情形，我们会采取合理措施保护您个人信息安全，包括及时停止继续收集个人信息的活动；停止运营的通知将以逐⼀送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。
    </p>
    <p class="p1 p2">
      5.7. 我们将收集到的您的个人信息存放在中华人民共和国境内，具体位置为阿里云存储服务器，阿里云将协助我们为您提供数据存储安全服务。如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的单独同意，并向您告知个人信息出境的目的、接收方、安全保障措施、安全风险等情况。此外，我们还将履行法律法规规定的跨境提供个人信息的安全保障义务，可能包括通过数据跨境安全评估或认证、与境外接收方签订数据处理协议。
    </p>
    <p class="p1">
      6. 您的权利
    </p>
    <p class="p1 p2">
      6.1. 访问权您可以通过如下方式访问您的个人信息：
    </p>
    <p class="p1 p3">
      6.1.1.账号信息：您可以登录子弹工作平台或网站查询、管理（更新、删除）使用蓝豚互动服务时提交的基本信息（基本资料）和联系方式等信息，进行账号关联或身份认证等。
    </p>
    <p class="p1 p3">
      6.1.2.使用信息：您可以在我们的平台、网站等服务中查阅使用记录信息等，您也可通过本协议文末提供的方式联系我们访问这些信息。请您注意，为了确保您本人行使权利的真实性，我们将在核实您的身份后提供，但法律法规另有约定的除外；
    </p>
    <p class="p1 p3">
      6.1.3.其他信息：如您在此访问过程中遇到操作问题或如需获取其他前述无法获知的个人信息内容，您可通过本协议文末提供的方式联系我们，我们将在核实您的身份后提供，但法律法规另有约定的除外。
    </p>
    <p class="p1 p2">
      6.2. 更正权
    </p>
    <p class="p1 p3">
      6.2.1.如果您需要变更您子弹工作账户的实名认证信息，您需通过客服、电话、邮件等方式联系蓝豚互动，我们会协助您进行相应操作。
    </p>
    <p class="p1 p3">
      6.2.2.经对您的身份进行验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或更新，您可以自行在我们的平台或网站中进行更正，或在特定情况下，尤其是数据错误时，通过本协议文末提供的联系方式将您的更正申请提交给我们，要求我们更正或更新您的数据，但法律法规另有规定的除外。但出于安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息。
    </p>
    <p class="p1 p2">
      6.3. 删除权根据相关规范，在以下情形中，您可以向我们提出删除个人信息的请求：
    </p>
    <p class="p1 p3">
      6.3.1. 如果处理目的已实现或者为实现处理目的不再必要;
    </p>
    <p class="p1 p3">
      6.3.2. 如果我们停止提供产品或者服务, 或者保存期限已届满;
    </p>
    <p class="p1 p3">
      6.3.3. 如果您已撤回同意;
    </p>
    <p class="p1 p3">
      6.3.4. 如果我们违反法律、行政法规或者违反约定处理您的个人信息；
    </p>
    <p class="p1 p3">
      6.3.5. 法律、行政法规规定的其他情形。您知悉并理解，为保障行使个人信息权利的安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独⽴授权。当您从我们的服务中删除信息后，我们将在合理期限内及时删除您的个人信或者对其进行匿名化处理。
    </p>
    <p class="p1 p2">
      6.4复制权如您需要复制您的个人信息，您可以通过本协议文未提供的方式联系我们，在核实您的身份后，我们将向您提供您在我们的服务中的个人信息本(例如基本资料、身份信息)，但法律法规另有规定的除外。
    </p>
    <p class="p1 p2">
      6.5. 撤回同意权
    </p>
    <p class="p1 p3">
      6.5.1.如您想更改相关功能的授权范围（包括位置、通讯录、相机），您可以通过您的硬件设备修改个人设置、或在我们的产品或服务中的相关功能设置界面进行操作处理。如您在此过程中遇到操作问题，可以通过本协议文末提供的方式联系我们。
    </p>
    <p class="p1 p3">
      6.5.2.当您撤回相关个人信息收集的授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但您知悉并同意，除非您行使前述“删除权”，否则您的该行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。
    </p>
    <p class="p1 p2">
      6.6. 注销权
    </p>
    <p class="p1 p3">
      6.6.1.您可以通过联系我们的客服或通过其他我们公示的方式注销您的账号（但法律法规另有规定的除外），⼀旦您注销账号，将无法使用我们提供的服务，因此请您谨慎操作。
    </p>
    <p class="p1 p3">
      6.6.2.我们为了保护您或他人的合法权益会结合您对我们提供的各产品的使用情况判断是否支持您的注销请求，比如若您的付费服务期限未满，则我们不会⽴即支持您的请求，而会提示您先进行相应处理。
    </p>
    <p class="p1 p3">
      6.6.3.除法律法规另有规定外，注销账号之后，您该账号内的所有信息将被清空，我们将并根据您的要求删除您的个人信息。您通过第三方账号（如微信、微博、QQ等）授权登录我们的服务时，需要向第三方申请注销账号。
    </p>
    <p class="p1 p2">
      6.7. 提前获知产品和服务停止运营我们愿⼀直陪伴您，若因特殊原因导致我们部分或全部产品和服务被迫停止运营，我们将提前15日在产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等，法律法规另有规定的除外。
    </p>
    <p class="p1 p2">
      6.8. 要求解释说明个人信息处理规则在某些业务功能中，我们可能存在依据信息系统、算法等在内的自动化决策机制做出某些商业决定，如向您展示个性化推荐内容。如果您认为这些决定显著影响您的合法权益，您有权要求我们对处理您个人信息的规则做出解释，我们也将相应为您提供充分的救济方式。
    </p>
    <p class="p1 p2">
      6.9. 响应您的上述请求为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </p>
    <p class="p1 p3">
      6.9.1.对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取⼀定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝，并向您具体说明拒绝的理由。
    </p>
    <p class="p1 p3">
      6.9.2. 但请注意，在以下情形中，按照法律法规要求，我们将无法响应您的更正、删除、注销信息等请求：
    </p>
    <p class="p1 p4">
      (a) 与国家安全、国防安全直接相关的；
    </p>
    <p class="p1 p4">
      (b) 与公共安全、公共卫生、重大公共利益直接相关的；
    </p>
    <p class="p1 p4">
      (c) 与犯罪侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p class="p1 p4">
      (d) 有充分证据表明您存在主观恶意或滥用权利的
    </p>
    <p class="p1 p4">
      (e) 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
    </p>
    <p class="p1 p4">
      (f) 涉及商业秘密的。
    </p>
    <p class="p1">
      7. 我们如何处理未成年人的个人信息
    </p>
    <p class="p1 p2">
      7.1. 我们主要面向成人提供产品和服务。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为不满14周岁的未成年人，我们要求您请您的父母或监护人仔细阅读本协议，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
    </p>
    <p class="p1 p2">
      7.2. 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    </p>
    <p class="p1 p2">
      7.3. 如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据，并且我们不承担由于此等删除对您造成的损失。
    </p>
    <p class="p1 p2">
      7.4. 如果任何时候监护人有理由相信我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过本协议第10条指定的联系方式联系我们，我们会采取措施尽快删除相关数据。
    </p>
    <p class="p1">
      8. 您的个人信息如何在全球范围转移
    </p>
    <p class="p1 p2">
      8.1. 我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
    </p>
    <p class="p1 p3">
      8.1.1.法律法规有明确规定；
    </p>
    <p class="p1 p3">
      8.1.2.获得您的明确授权；
    </p>
    <p class="p1 p3">
      8.1.3.您通过互联网进行跨境交易等个人主动行为。
    </p>
    <p class="p1 p2">
      8.2. 针对以上情形，我们会确保依据本协议对您的个人信息提供⾜够的保护。
    </p>
    <p class="p1">
      9. 本协议如何更新
    </p>
    <p class="p1 p2">
      9.1. 如果互联网信息服务提供商的同行实践，由于产品和服务不断更新变化，为了全面、适时地向您告知个人信息处理规则，我们的隐私协议可能变更。
    </p>
    <p class="p1 p2">
      9.2. 未经您明确同意，我们不会削减您按照本协议所应享有的权利。我们会在专门页面上发布对隐私协议所做的任何变更。
    </p>
    <p class="p1 p2">
      9.3. 对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过网站公示的方式进行通知甚至向您提供弹窗提示，说明隐私权协议的具体变更内容），并会在变更生效前给您预留合理的期限供您考虑是否接受变更。若您在新版本隐私协议和用户协议生效后继续使用我们的产品和服务，即表示您已充分阅读并愿意受更新后的隐私协议和用户协议约束，若您不同意该等变更可以停止使用我们的产品和服务。
    </p>
    <p class="p1 p2">
      9.4. 本协议所指的重大变更包括但不限于：
    </p>
    <p class="p1 p3">
      9.4.1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p class="p1 p3">
      9.4.2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p class="p1 p3">
      9.4.3. 个人信息共享、转让或公开披露的主要对象发生变化；
    </p>
    <p class="p1 p3">
      9.4.4. 您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </p>
    <p class="p1 p3">
      9.4.5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p class="p1 p3">
      9.4.6. 个人信息安全影响评估报告表明存在高风险时。
    </p>
    <p class="p1">
      10. 如何联系我们您对本协议内容有任何疑问和意见，或者您对蓝豚互动对本协议的实践以及操作上有任何疑问和意见，请与我们联系。您可以将您的问题发送至lantunhudong@163.com，或寄到如下地址：江苏省苏州市昆山市花桥镇商祥路36领峰国际1号楼601（收），邮编：215300。⼀般情况下，我们将在15日内回复您的请求。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向蓝豚互动住所地人民法院提起诉讼来寻求解决方案
    </p>
    <p class="p1">
      向该第三方提供您的个人信息，您的信息应当适用该第三方的隐私声明或类似协议，我们对任何第三方不当使用或披露由您提供的信。
    </p>
  </div>
</template>
<style scoped>
h2 {
  text-align: center;
}

h4 {
  text-align: right;
}

.container {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 16px;
  user-select: none;
}
p {
  line-height: 26px;
}
</style>